import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import qs from 'qs'
import { isWindow } from '../utils/commonUtil'
import { below } from '../utils/media-query'
import setGeoTags from '../utils/geoFetch'

import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import {
  Banner,
  BlogBody,
  BlogContent,
  BlogTitle,
  BlogWrapper,
} from '../styles'

const BlogWrapperFull = styled(BlogWrapper)`
  grid-column-end: span 2;
  border: none;
  margin: 0;
`

const PageBody = styled(BlogBody)`
  border: none;
  @media (min-width: 768px) {
    padding: 0;
  }
  @media (max-width: 980px){
    padding: 0;
  }
`
const PageHeader = styled.div`
  display: flex;
  & > div {
    flex-basis: 50%;
  }

  ${below.med`
    flex-direction: column;
    padding: 0rem 1.5rem 0.5rem;
  `}

  ${below.small`
    padding: 0rem 1.5rem 0rem;
    time{
      display: none;
    }
`}
`

const PageTitle = styled(BlogTitle)`
  padding: ${props => props.withBanner? '3.75rem 2.5rem 3.25rem 3rem':'2rem 2.5rem 3.25rem 1.75rem'};

  h2 {
    font-size: 3rem;
    line-height: 4rem;
    text-transform: capitalize;
    font-family : Raleway, Helvetica, sans-serif;
  }
  h3 {
    text-transform: none;
    span {
      text-transform: capitalize;
    }
    margin-bottom: 0.5rem;
  }
  time {
    text-transform: uppercase;
    font-weight: 600;
    }

  ${below.large`
    padding: 1.75rem 2.5rem 3.25rem 1rem;
    h2 {
      font-size: 2rem;
      text-align: left;
      line-height: 2.5rem;
    }
  `}
  ${below.med`
    padding: 1rem 2rem 2.25rem 1rem;
    h2 {
      font-size: 1.5rem;
      line-height: 2rem
      margin-bottom: 1rem;
    }
  `}

  ${below.small`
    padding: 0.5rem 0.25rem;
    h2 {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  `}
`
// const BlogContentFull = styled(BlogContent)`
//  .offers {
//     max-width: 90% !important;
//  }
// `

export class LincxPageTemplate extends React.Component {
  constructor (props) {
    super(props)
    const params = isWindow ? qs.parse(window.location.search.slice(1)) : {}
    this.state = {
      isMounting: true,
      hideAds: params['no-display'] && params['no-display'] !== 'false'
    }
  }
  componentDidMount () {
    this.setState({
      isMounting: false
    })
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isMounting && !this.props.disablePushNotification) {
      setGeoTags()
    }
  }

  render () {
    let {
      author,
      banner,
      content,
      contentComponent,
      date,
      helmet,
      pathname,
      title,
      showHeader,
    } = this.props
    const PostContent = contentComponent
    const { isMounting, hideAds } = this.state

    return (
      <BlogWrapperFull>
        {helmet ? helmet(isMounting, hideAds) : ''}
        {showHeader ? (
          <PageHeader data-testid="page-header">
            {
              banner ? (
                <Banner>
                  <Img fluid={banner.childImageSharp.fluid} />
                </Banner>
              ) : null
            }
            <PageTitle withBanner={!!banner}>
              <Link to={pathname}>
                <h2>{isMounting ? '' : title}</h2>
              </Link>
            {
              author ? <h3>by {author} </h3> : null
            }
            {
              date ? <time>{date}</time> : null
            }
            </PageTitle>
          </PageHeader>
        ) : null}
        <PageBody>
          <BlogContent>
            {this.state.isMounting ? null : (
              <PostContent data-testid="page-content" loadScripts={true} content={content} />
            )}
          </BlogContent>
        </PageBody>
      </BlogWrapperFull>
    )
  }
}

class LincxPage extends React.Component {
  renderHelmet = (isMounting, hideAds) => {
    let {
      markdownRemark: {
        frontmatter: { title, showAds}
      }
    } = this.props.data
    // render only essential meta tags when mounting or ads are hidden
    return isMounting || hideAds || !showAds ? (
      <Helmet titleTemplate='Path.Money - %s'>
        <title>{`${title}`}</title>
        <meta name='description' content={`${title}`} />
      </Helmet>
    ) : (
      <Helmet titleTemplate='Path.Money - %s'>
        <title>{`${title}`}</title>
        <meta name='description' content={`${title}`} />
        <script
          async
          src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
        />
        <script>
          {`(adsbygoogle = window.adsbygoogle || []).push({
             google_ad_client: "ca-pub-6867008335629681",
             enable_page_level_ads: true
            });`}
        </script>
        <link rel='dns-prefetch' href='//exmarketplace.com' />
        <script
          async
          src='https://cdn.exmarketplace.com/bidder/adsbooster/eb_sf.js'
        />
        <script
          async
          src='https://cdn.exmarketplace.com/bidder/path_money/path_money.dfp.js'
        />
      </Helmet>
    )
  }

  render () {
    const {
      data: { markdownRemark: post },
      location
    } = this.props
    return (
      <Layout IsBlogPage={true} IsPageLayout={true} IsLincxPage={true}>
        <LincxPageTemplate
          author={post.frontmatter.author}
          banner={post.frontmatter.banner}
          contentComponent={HTMLContent}
          content={post.html}
          date={post.frontmatter.date}
          helmet={this.renderHelmet}
          pathname={location.pathname}
          title={post.frontmatter.title}
          showHeader={post.frontmatter.showHeader}
          disablePushNotification={post.frontmatter.disablePushNotification}
        />
      </Layout>
    )
  }
}

LincxPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default LincxPage

export const pageQuery = graphql`
  query LincxPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        author
        date(formatString: "MMMM DD, YYYY")
        showHeader
        showAds
        disablePushNotification
        banner {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
